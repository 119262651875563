/* Blocks */
.hidden {
	display: none;
}

p {
	margin: 0;
}

.reg {
	padding: 160px 0 80px 0;
	flex-grow: 1;
}

@media (min-width: 768px) {
	.reg {
		padding: 230px 0 80px 0;
	}
}

@media (max-width: 360px) {
	.reg .container {
		max-width: 1822px;
	}
}

.reg__breadcrumbs {
	display: none;
	align-items: center;
}

@media (min-width: 768px) {
	.reg__breadcrumbs {
		display: flex;
	}
}

.reg__breadcrumbs span {
	display: inline-block;
	width: 4px;
	height: 8px;
	margin: 0 14px;
	background: url(../../img/lk/span.svg) no-repeat center/cover;
}

.reg__breadcrumbs-item {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.reg__main-title {
	font-weight: 600;
	font-size: 36px;
	line-height: 48px;
	color: #0C143B;
	margin: 12px 0 24px 0;
}

@media (min-width: 1200px) {
	.reg__main-title {
		font-size: 42px;
		line-height: 48px;
		margin: 12px 0 32px 0;
	}
}

@media (min-width: 1200px) {
	.reg__inner {
		display: grid;
		grid-template-columns: 1fr 400px;
		column-gap: 30px;
		align-items: start;
	}
}

.reg__content {
	grid-column: 1/2;
}

.reg__allert {
	display: flex;
	align-items: start;
	background: rgba(19, 168, 252, 0.1);
	padding: 24px;
	font-weight: 600;
	font-size: 15px;
	line-height: 133.98%;
	color: #405078;
	margin-bottom: 16px;
}

@media (min-width: 768px) {
	.reg__allert {
		font-size: 16px;
	}
}

.reg__allert img {
	margin-right: 16px;
}

.reg__allert p {
	max-width: 738px;
}

.reg__item:not(:last-child) {
	margin-bottom: 16px;
}

.reg__item--active .reg__item-change {
	display: none;
}

@media (min-width: 992px) {
	.reg__item--active .reg__item-info--book {
		grid-template-columns: 100px 1fr;
	}
}

@media (min-width: 1200px) {
	.reg__item--active .reg__item-info {
		padding-bottom: 30px;
	}
}

.reg__item--active .reg__item-more {
	display: block;
}

.reg__item--active .reg__item-more--book {
	display: grid;
}

@media (min-width: 992px) {
	.reg__item--active .reg__item-count {
		font-weight: bold;
		font-size: 20px;
		padding: 10px 15px;
		font-size: 18px;
	}
}

@media (min-width: 992px) {
	.reg__item--active .reg__item-title {
		font-size: 30px;
	}
}

@media (min-width: 1200px) {
	.reg__item--active .reg__item-title {
		font-size: 32px;
	}
}

.reg__item--active .reg__item-info--profile {
	display: none;
}

.reg__item--active .reg__item-more--profile {
	border-top: none;
	padding-top: 0;
}

.reg__item-header {
	padding: 16px 24px;
	background: #F2F4F8;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	color: #405078;
	display: flex;
	align-items: center;
}

@media (min-width: 992px) {
	.reg__item-header {
		padding: 24px;
	}
}

.reg__item-count {
	padding: 5px 10px;
	background: #DADEE2;
	border-radius: 50%;
	margin-right: 8px;
	transition: .3s all ease;
}

@media (min-width: 992px) {
	.reg__item-count {
		font-weight: bold;
		padding: 6px 11px;
		font-size: 18px;
	}
}

.reg__item-title {
	font-size: 18px;
	transition: .3s all ease;
}

.reg__item-change {
	font-size: 14px;
	margin-left: auto;
	color: #13A8FC;
	border-bottom: 1px solid #000;
	border-color: #13A8FC;
}

.reg__item-change:hover {
	cursor: pointer;
	opacity: .7;
}

@media (min-width: 768px) {
	.reg__item-change {
		font-size: 16px;
	}
}

@media (min-width: 992px) {
	.reg__item-change {
		font-size: 18px;
	}
}

.reg__item-content {
	padding: 24px;
	border: 2px solid #F2F4F8;
}

.reg__item-info--book {
	display: grid;
	column-gap: 20px;
	grid-template-columns: 50px 1fr;
	transition: .3s all ease;
}

.reg__item-book-img {
	box-shadow: 0px 10px 50px 3px rgba(130, 146, 186, 0.45);
}

.reg__item-book-description {
	grid-column: 2/3;
	font-style: normal;
	font-size: 16px;
	line-height: 133.98%;
	color: #0C143B;
}

@media (min-width: 992px) {
	.reg__item-book-description {
		display: flex;
	}
}

.reg__item-book-descr {
	font-weight: 600;
	font-weight: normal;
	margin-bottom: 8px;
}

.reg__item-book-name {
	font-weight: bold;
}

.reg__item-book-name span {
	font-weight: normal;
	font-size: 14px;
}

@media (min-width: 768px) {
	.reg__item-book-name span {
		font-size: 18px;
		font-weight: bold;
	}
}

@media (min-width: 992px) {
	.reg__item-book-name span {
		font-weight: bold;
	}
}

.reg__item-book-name span b {
	font-weight: bold;
}

@media (min-width: 768px) {
	.reg__item-book-name {
		font-size: 18px;
	}
}

@media (min-width: 992px) {
	.reg__item-book-name br {
		display: none;
	}
}

.reg__item-book-type {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	margin-top: 10px;
}

.reg__item-book-count {
	white-space: nowrap;
	margin-left: auto;
	display: inline-block;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
}

.reg__item-book-count span {
	font-weight: normal;
}

.reg__item-book-price {
	white-space: nowrap;
	display: inline-block;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
	color: #0C143B;
	margin-left: 15px;
}

@media (min-width: 768px) {
	.reg__item-book-price {
		font-size: 22px;
	}
}

@media (min-width: 992px) {
	.reg__item-book-price {
		margin-left: 30px;
	}
}

@media (min-width: 1440px) {
	.reg__item-book-price {
		margin-left: 40px;
	}
}

.reg__item-more {
	display: none;
	padding-top: 20px;
}

@media (min-width: 1200px) {
	.reg__item-more {
		padding-top: 30px;
		border-top: 1px solid #F2F4F8;
	}
}

.reg__item-more--book {
	column-gap: 20px;
	grid-template-columns: 50px 1fr;
}

@media (min-width: 992px) {
	.reg__item-more--book {
		grid-template-columns: 1fr;
	}
}

.reg__item-more--book .reg__form {
	grid-column: 2/3;
}

@media (min-width: 992px) {
	.reg__item-more--book .reg__form {
		grid-column: unset;
	}
}

.reg__item-more--book .reg__input {
	max-width: 380px;
}

.reg__item-more--book .reg__item-more-btns {
	grid-column: 2/3;
	display: flex;
	flex-direction: column;
	align-items: start;
}

@media (min-width: 992px) {
	.reg__item-more--book .reg__item-more-btns {
		flex-direction: row;
		align-items: end;
		display: flex;
		justify-content: space-between;
		grid-column: unset;
	}
}

.reg__item-promocode-btn {
	margin: 0 0 15px 0;
	padding: 12px 40px;
	font-size: 16px;
	color: #F2F4F8 !important;
	background: #DADEE2;
	border-radius: 4px;
	display: inline-block;
	font-style: normal;
	font-weight: 800;
	font-size: 16px;
	line-height: 20px;
}

.reg__item-promocode-btn:hover {
	cursor: pointer;
}

@media (min-width: 992px) {
	.reg__item-promocode-btn {
		font-size: 20px;
	}
}

.reg__item-promocode-message {
	display: block;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #13A8FC;
	margin-bottom: 15px;
}

@media (min-width: 992px) {
	.reg__item-promocode-message {
		margin-bottom: 0;
		font-size: 16px;
	}
}

.reg__item-more-btns {
	margin-top: 30px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.reg__item-more-btn {
	font-weight: 800;
	font-size: 16px;
	line-height: 20px;
	color: #F2F4F8 !important;
	background: #13A8FC;
	border-radius: 0px 4px 4px 0px;
	padding: 12px 32px;
}

.reg__item-more-btn:hover {
	cursor: pointer;
}

.reg__item-more-help {
	margin-top: 30px;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	color: #6B7B8E;
}

@media (min-width: 991px) {
	.reg__item-more-help {
		font-size: 16px;
	}
}

.reg__item-more-help--sheep {
	padding-left: 30px;
}

.reg__confirm {
	margin-top: 16px;
}

.reg__confirm form {
	display: flex;
	flex-direction: column;
	align-items: end;
}

@media (min-width: 768px) {
	.reg__confirm form {
		flex-direction: row;
		justify-content: space-between;
	}
}

.reg__confirm form button {
	margin-top: 0;
}

.reg__confirm form button:disabled {
	opacity: .5;
}

.reg__more {
	max-width: 428px;
	margin-top: 4px;
	margin-bottom: 30px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
}

.reg__footer {
	position: relative;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.reg__footer-checkbox {
	position: absolute;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	background: #F2F4F8;
	border-radius: 6px;
}

.reg__footer-checkbox input {
	display: none;
}

.reg__footer-checkbox-icon:before {
	display: none;
	content: url("../../img/registration/check.svg");
}

input:checked + .reg__footer-checkbox-icon:before {
	display: block;
}

.reg__footer-text {
	padding-left: 36px;
}

.reg__footer-text a {
	color: #13A8FC;
	font-weight: 600;
}

.reg__input--place .input-label {
	left: 44px !important;
}

.reg__input--place .input-label::before {
	content: '';
	position: absolute;
	top: 50%;
	left: -32px;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background: url(../../img/reg/search.svg);
}

.reg__input {
	position: relative;
}

.reg__input--more-info {
	display: flex;
	flex-direction: column;
}

@media (min-width: 1200px) {
	.reg__input--more-info {
		align-items: center;
		flex-direction: row;
	}
}

.reg__input .error {
	border: 1px solid red;
}

.reg__input input {
	background: #F2F4F8;
	border-radius: 6px;
	width: 100%;
	max-width: 100%;
	border: none;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	transition: .5s opacity ease;
}

@media (min-width: 1200px) {
	.reg__input input {
		max-width: 768px;
	}
}

.reg__input input::placeholder {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.reg__input input:hover {
	opacity: .6;
}

.reg__input input:focus + .input-label {
	display: none;
}

.reg__input input:not(:placeholder-shown) + .input-label {
	display: none;
}

.reg__input textarea {
	background: #F2F4F8;
	border-radius: 6px;
	width: 100%;
	max-width: 100%;
	border: none;
	padding: 12px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	transition: .5s opacity ease;
	resize: none;
	min-height: 120px;
}

@media (min-width: 1200px) {
	.reg__input textarea {
		max-width: 768px;
	}
}

.reg__input textarea::placeholder {
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
}

.reg__input textarea:hover {
	opacity: .6;
}

.reg__input textarea:focus + .input-label {
	display: none  !important;
}

.reg__input textarea:not(:placeholder-shown) + .input-label {
	display: none !important;
}

@media (min-width: 768px) {
	.reg__input textarea {
		min-height: 120px;
	}
}

.reg__input button {
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #FFFFFF;
	padding: 12px 30px;
	background: #FE334A;
	border-radius: 4px;
	border: none;
	margin-top: 20px;
}

.reg__ragio-btns-group:not(:last-child) {
	margin-bottom: 16px;
}

.reg__ragio-input {
	appearance: none;
}

.reg__ragio-input:checked + .reg__ragio-label::after {
	width: 20px;
	height: 20px;
	left: 2px;
}

.reg__ragio-label {
	position: relative;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	color: #405078;
	padding-left: 30px;
}

@media (min-width: 992px) {
	.reg__ragio-label {
		font-size: 16px;
	}
}

@media (min-width: 1200px) {
	.reg__ragio-label {
		font-size: 18px;
	}
}

.reg__ragio-label::before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background: #F2F4F8;
	border-radius: 50%;
}

.reg__ragio-label::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
	width: 0;
	height: 0;
	background: #FE334A;
	border-radius: 50%;
	transform-origin: center;
	transition: .3s all ease;
}

.reg__ragio-label span {
	margin-left: 8px;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #3E9325;
}

.reg__radio-btns {
	display: grid;
}

.reg__form {
	display: grid;
	row-gap: 12px;
}

.reg .input-label {
	position: absolute;
	left: 12px;
	top: 13px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #6B7B8E;
	pointer-events: none;
}

.reg .input-label:focus {
	display: none;
}

.reg .input-label span {
	color: #FE334A;
}

.reg__product {
	margin-top: 30px;
	padding: 30px;
	background: #F2F4F8;
}

@media (min-width: 1200px) {
	.reg__product {
		margin-top: 10px;
		grid-column: 2/3;
		grid-row: 1/3;
	}
}

.reg__product-info {
	display: flex;
	justify-content: space-between;
	background: url(../../img/common/dots.png) repeat-x 0 15px;
}

.reg__product-info:not(:last-child) {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #DADEE2;
}

.reg__product-descr {
	font-weight: 400;
	font-size: 15px;
	line-height: 130%;
	background: #F2F4F8;
}

.reg__product-descr span {
	font-weight: 600;
	font-size: 15px;
	line-height: 130%;
	color: #3E9325;
}

.reg__product-descr--resume {
	font-weight: bold;
	font-size: 16px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}
