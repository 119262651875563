@import 'common/_variables';
.hidden{display: none;}
p{
    margin: 0;
}
.reg {

    padding: 160px 0 80px 0;
    @media (min-width:768px) {
        padding: 230px 0 80px 0;
    }
    flex-grow: 1;
    .container{
        @media (max-width:360px) {
            max-width: 1822px;
        }
    }

    &__breadcrumbs {
        display: none;
        align-items: center;
        @media (min-width:768px) {
            display: flex;
        }
        span{
            display: inline-block;
            width: 4px;
            height: 8px;
            margin: 0 14px;
            background:  url(../../img/lk/span.svg)no-repeat center/cover;
        }
    }

    &__breadcrumbs-item {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
    }

    &__main-title {
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: #0C143B;
        margin: 12px 0 24px 0;
       
        @media (min-width:1200px) {
            font-size: 42px;
            line-height: 48px;
            margin: 12px 0 32px 0;
        }
    }

    &__inner {

        @media (min-width:1200px) {
            display: grid;
            grid-template-columns:  1fr 400px;
            column-gap: 30px;
            align-items: start;
        }
       
    }

    &__content{
        grid-column: 1/2;
    }
// REG 
// REG 
// REG 
// REG 
// REG 

    &__allert {
        display: flex;
        align-items: start;
        background: rgba(19, 168, 252, 0.1);
        padding: 24px;
        font-weight: 600;
        font-size: 15px;
        line-height: 133.98%;
        color: #405078;
        margin-bottom: 16px;
        @media (min-width:768px) {
            font-size: 16px;
        }
        img{
            margin-right: 16px;
        }
        p{
            max-width: 738px;
        }

    }

    &__list {
    }

    &__item {
        &:not(:last-child){
            margin-bottom: 16px;
        }
        // ACTIVE
        // ACTIVE
        // ACTIVE
        &--active{
            .reg__item-change{
                display: none;
            }
            .reg__item-info{
                &--book{
                    @media (min-width:992px) {
                        grid-template-columns: 100px 1fr;
                        
                    }
                }
                @media (min-width:1200px) {
                   padding-bottom: 30px;
                   
                }
            }
            .reg__item-more{
                display: block;
                &--book{
                    display: grid;
                }
            }
            .reg__item-count{
                @media (min-width:992px) {
                    font-weight: bold;
                    font-size: 20px;
                    padding: 10px 15px;
                    font-size: 18px;
                }
            }
            .reg__item-title{
                @media (min-width:992px) {
                    font-size: 30px;
                }
                @media (min-width:1200px) {
                    font-size: 32px;
                }
            }
            .reg__item-info--profile{
                display: none;
            }
            .reg__item-more--profile{
                border-top: none;
                padding-top: 0;
            }

        }
    }

    &__item-header {
        padding: 16px 24px;
        background: #F2F4F8;
        font-weight: 700;
        font-size: 16px;
        line-height: 18px;
        color: #405078;
        display: flex;
        align-items: center;
        @media (min-width:992px) {
            padding: 24px;
        }
        

    }

    &__item-count {
        padding: 5px 10px;
        background: #DADEE2;
        border-radius: 50%;
        margin-right: 8px;
        transition: .3s all ease;
        
        @media (min-width:992px) {
            font-weight: bold;
            padding: 6px 11px;
            font-size: 18px;
        }
    }

    &__item-title {
        font-size: 18px;
        transition: .3s all ease;
        @media (min-width:992px) {
            // font-size: 30px;
        }
    }

    &__item-change {
        font-size: 14px;
        margin-left: auto;
        color: #13A8FC;
        border-bottom: 1px solid #000;
        border-color: #13A8FC;
        &:hover{
            cursor: pointer;
            opacity: .7;
        }
        @media (min-width:768px) {
            font-size: 16px;
        }
        @media (min-width:992px) {
            font-size: 18px;
        }
    }

    &__item-content {
        padding: 24px;
        border: 2px solid #F2F4F8;
    }

    &__item-info {
        // padding-bottom: 20px;
        @media (min-width:1200px) {
            // padding-bottom: 30px;
            // border-top: 1px solid #F2F4F8; 
        }
        &--book{
            display: grid;
            column-gap: 20px;
            grid-template-columns: 50px 1fr;
             transition: .3s all ease;
            @media (min-width:992px) {
                // grid-template-columns: 100px 1fr;
                
            }
        }
        

    }

    &__item-book-img {
        box-shadow: 0px 10px 50px 3px rgba(130, 146, 186, 0.45);
        
    }

    &__item-book-description {
        grid-column: 2/3;
        font-style: normal;
        font-size: 16px;
        line-height: 133.98%;
        color: #0C143B;
        @media (min-width:992px) {
            display: flex;
        }
        
    }
    &__item-book-descr{
        font-weight: 600;
        font-weight: normal;
        margin-bottom: 8px;
    }
    &__item-book-name{
        font-weight: bold;
        span{
            font-weight: normal;
            font-size: 14px;
            @media (min-width:768px) {
                font-size: 18px;
                font-weight: bold;
            }
            @media (min-width:992px) {
                font-weight: bold;
            }
            b{
                font-weight: bold;
            }
        }
        @media (min-width:768px) {
            font-size: 18px;
        }
        @media (min-width:992px) {
            br{
                display: none;
            }
        }
    }
    &__item-book-type{
        // display: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
    }
    &__item-book-count {
        white-space: nowrap;
        margin-left: auto;
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        span{
            font-weight: normal;
        }
        
    }

    &__item-book-price {
        white-space: nowrap;
        display: inline-block;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        color: #0C143B;
        margin-left: 15px;
        @media (min-width:768px) {
            font-size: 22px;
        }
        @media (min-width:992px) {
            margin-left: 30px;
        }
        @media (min-width:1440px) {
            margin-left: 40px;
        }

    }

    &__item-more {
        display: none;
        padding-top: 20px;
        @media (min-width:1200px) {
            padding-top: 30px;
            border-top: 1px solid #F2F4F8; 
        }
        
        &--book{
            // display: grid;
            column-gap: 20px;
            grid-template-columns: 50px 1fr;
            @media (min-width:992px) {
                grid-template-columns: 1fr;
            }
            .reg__form{
                grid-column: 2/3;
                @media (min-width:992px) {
                    grid-column:unset;
                }
            }
            .reg__input{
                // text-transform: uppercase;
                max-width: 380px;
            }
            .reg__item-more-btns {
                grid-column: 2/3;
                display: flex;
                flex-direction: column;
                align-items: start;
                @media (min-width:992px) {
                    flex-direction: row;
                    align-items: end;
                    display: flex;
                    justify-content: space-between;
                    grid-column:unset;
                }
            }
        }
    }
    &__item-promocode-btn{
        margin: 0 0 15px 0;
        padding: 12px 40px;
        font-size: 16px;
        color: #F2F4F8 !important;
        background: #DADEE2;
        border-radius: 4px;
        display: inline-block;
        font-style: normal;
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        &:hover{
            cursor: pointer;
        }
        @media (min-width:992px) {
            font-size: 20px;
        }
        
    }
    &__item-promocode-message{
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #13A8FC;
        margin-bottom: 15px;
        @media (min-width:992px) {
            margin-bottom: 0;
            font-size: 16px;
        }
    }

    &__form {
    }

    &__input {
    }

    &__item-more-btns {
        margin-top: 30px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__item-more-btn {
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        color: #F2F4F8 !important;
        background: #13A8FC;
        border-radius: 0px 4px 4px 0px;
        padding: 12px 32px;
        &:hover{
            cursor: pointer;
            
        }
    }

    &__item-more-btn--prev {
    }

    &__item-more-btn--next {
    }

    &__item-more-help {
        margin-top: 30px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #6B7B8E;
        @media (min-width:991px) {
            font-size: 16px;
        }
        &--sheep{
            padding-left: 30px;
        }

    }
    &__confirm{
        margin-top: 16px;
        form{
            display: flex;
            flex-direction: column;
            align-items: end;
            @media (min-width:768px) {
                flex-direction: row;
                justify-content: space-between;
            }
            button{
                margin-top: 0;
                &:disabled{
                    opacity: .5;
                }
            }
           
        }
    }
    &__more {
        max-width: 428px;
        margin-top: 4px;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }

    &__footer {
        position: relative;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
        &-checkbox {
            position: absolute;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background: #F2F4F8;
            border-radius: 6px;

            input {display: none;}

            &-icon:before {
                display: none;
                content: url("../../img/registration/check.svg");

                input:checked + & {display: block;}
            }
        }

        &-text {
            padding-left: 36px;

            & a {
                color: #13A8FC;
                font-weight: 600;
            }
        }

    }
    &__input--place{
        .input-label{
            left: 44px !important;
            &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: -32px;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                background: url(../../img/reg/search.svg);
            }
        }
    }
    &__input {
        position: relative;
        &--more-info{
            display: flex;
            
            flex-direction: column;
            @media (min-width:1200px) {
                align-items: center;
                flex-direction: row;
            }
            
        }
        .error{
            border: 1px solid red;
        }
        input{
            background: #F2F4F8;
            border-radius: 6px;
            width: 100%;
            max-width: 100%;
            border: none;
            padding: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            transition: .5s opacity ease;
            @media (min-width:1200px) {
                max-width: 768px;
            }
            &::placeholder{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #6B7B8E;
            }
            &:hover{
                opacity: .6;
            }
            &:focus + .input-label{
                display: none;
            }
            &:not(:placeholder-shown) + .input-label{
                display: none;
            }
            
        }
        textarea{
            background: #F2F4F8;
            border-radius: 6px;
            width: 100%;
            max-width: 100%;
            border: none;
            padding: 12px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            transition: .5s opacity ease;
            resize: none;
            min-height: 120px;
            @media (min-width:1200px) {
                max-width: 768px;
            }
            &::placeholder{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #6B7B8E;
            }
            &:hover{
                opacity: .6;
            }
            &:focus + .input-label{
                display: none  !important;
            }
            &:not(:placeholder-shown) + .input-label{
                display: none !important;
            }
            @media (min-width:768px) {
                min-height: 120px;
            }
        }
        button{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            padding: 12px 30px;
            background: #FE334A;
            border-radius: 4px;
            border: none;
            margin-top: 20px;
        }
        
    }
    &__ragio-btns-group {
        &:not(:last-child){
            margin-bottom: 16px;
        }
    }
    &__ragio-input{
        appearance: none;
    }
    &__ragio-input:checked + .reg__ragio-label::after{
        width: 20px;
        height: 20px;
        left:2px;
        
    }
    &__ragio-label {
        position: relative;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #405078;
        padding-left: 30px;
        @media (min-width:992px) {
            font-size: 16px;
        }
        @media (min-width:1200px) {
            font-size: 18px;
        }
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            background: #F2F4F8;
            border-radius: 50%;

        }
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            background: #FE334A;
            border-radius: 50%;
            transform-origin: center;
            transition: .3s all ease;
        }
        span{
            margin-left: 8px;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #3E9325;
           
        }
    }

    &__radio-btns {
        display: grid;
    }

    &__form{
        display: grid;
        row-gap: 12px;
    }
    .input-label{
        position: absolute;
        left: 12px;
        top: 13px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #6B7B8E;
        pointer-events: none;
        &:focus{
            display: none;
        }
        span{
            color: #FE334A;
        }
    }


    &__product {
        margin-top: 30px;
        padding: 30px;
        background: #F2F4F8;
        @media (min-width:1200px) {
            margin-top: 10px;
            // margin-top: 0;
            grid-column: 2/3;
            grid-row: 1/3;
        }
    }

    &__product-info {
        display: flex;
        justify-content: space-between;
        background: url(../../img/common/dots.png) repeat-x 0 15px;
        &:not(:last-child){
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid #DADEE2;
        }
    }

    &__product-descr {
        font-weight: 400;
        font-size: 15px;
        line-height: 130%;
        background: #F2F4F8;
        span{
            font-weight: 600;
            font-size: 15px;
            line-height: 130%;
            color: #3E9325;
        }
    }

    &__product-descr--resume {
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

}

